import { Button, Input, Modal, Space, message } from "antd";
import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
// component
import { IconCircle, IconGarbage, IconPen } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
// api
import useAPI from "@/hooks/useAPI";
import { getStaff } from "@/service/apis/PMS/util";
import { deleteInvProject, getInvProject, txnInvHd } from "@/service/apis/WMS/Inventory";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";

import { deleteInvCheck, getInvEntory } from "../moke";
import ModalContainer from "./ModalContainer/index";
import ModalContainer4030 from "./ModalContainer/index4030";

const Project = forwardRef((props, ref) => {
	const { s_tabKey, set_s_tabKey, s_wh, c_lightData, set_c_lightData } = props;
	const { t } = useTranslation();
	const call_getStaff = useAPI(getStaff);
	const call_getInvProject = useAPI(getInvProject);
	const call_deleteInvProject = useAPI(deleteInvProject);
	const call_txnInvHd = useAPI(txnInvHd);
	const [s_staffs, set_s_staffs] = useState({});
	const [is_spinning, set_is_spinning] = useState(false);
	const [is_showModal, set_is_showModal] = useState(false);
	const [s_tableData, set_s_tableData] = useState([]);
	// const [s_tabKey, set_s_tabKey] = useState("4020");
	const [s_editData, set_s_editData] = useState({});

	const [s_searchData, set_s_searchData] = useState({
		wh: [],
		N: "",
	});

	const handlChange = (type, value) => {
		// 每次變更都重第一頁開始
		set_s_searchData((prev) => {
			prev[type] = value;
			return { ...prev };
			// return { ...prev, page: 1, pageSize: 20 };
		});
	};

	const editData = (e, rowData) => {
		set_s_editData(rowData);
		set_is_showModal(true);
	};

	const deleteData = (e, rowData) => {
		Modal.confirm({
			title: t("删除确认"),
			icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
			content: t("请先确认资料无误，再删除"),
			okText: t("确认"),
			onOk: () => call_deleteInvProject.request(rowData),
			cancelText: t("取消"),
		});

		e.stopPropagation(); // 這裡不要開啟表身
	};

	useImperativeHandle(ref, () => ({
		Call_project: () => call_getInvProject.request({ ...s_searchData, opcode: s_tabKey }),
	}));


	useEffect(() => {
		call_getStaff.request();
	}, []);

	// 第一次渲染
	useEffect(() => {
		call_getInvProject.request({ ...s_searchData, opcode: s_tabKey });
		set_c_lightData({});
	}, [s_tabKey]);

	// 取得員工資料
	useEffect(() => {
		const { data, status, msg } = call_getStaff;

		if (status === "suc") {
			const options = data?.reduce((prev, curr) => {
				prev[curr.staffID] = curr.peopleNM;
				return prev;
			}, {});
			set_s_staffs(options);
			// message.success(msg);
		}
		if (status === "err") {
			message.error(msg);
		}
	}, [call_getStaff.status]);

	useEffect(() => {
		const { status, msg } = call_txnInvHd;
		if (status === "load") {
			set_is_spinning(true);
		}
		if (status === "suc") {
			set_is_spinning(false);
			message.success(msg);
			call_getInvProject.request({ ...s_searchData, opcode: s_tabKey });
		}
		if (status === "err") {
			set_is_spinning(false);
			message.error(msg);
		}
	}, [call_txnInvHd.status]);

	// useAPI 取得盤點計畫
	useEffect(() => {
		const { status, data, msg } = call_getInvProject;

		if (status === "load") {
			set_is_spinning(true);
		}

		if (status === "suc") {
			message.success(msg);
			const _data =
				data?.map((item, index) => ({
					...item,
					index: index,
					key: index + 1,
					plandate: dayjs(item.plandate),
					planclosedate: dayjs(item.planclosedate),
				})) || [];

			set_s_tableData(_data);
			set_is_spinning(false);
			console.log(s_tableData)
		}
		if (status === "err") {
			message.error(msg);
			set_s_tableData([]);
			set_is_spinning(false);
		}
	}, [call_getInvProject.status]);


	// useAPI 刪除盤點資料
	useEffect(() => {
		const { status, msg } = call_deleteInvProject;
		if (status === "load") {
			set_is_spinning(true);
		}
		if (status === "suc") {
			message.success(msg);
			call_getInvProject.request({ ...s_searchData, opcode: s_tabKey });
		}
		if (status === "err") {
			message.error(msg);
			set_is_spinning(false);
		}
	}, [call_deleteInvProject.status]);

	const tableColumns = [

		{
			title: "單別", // 單別
			dataIndex: "opcode",
			align: "center",
			width: "10%",
		},
		{
			title: "作业单号", // 作業單號
			dataIndex: "jobN",
			align: "center",
			width: "10%",
		},
		{
			title: "盘点类别", // 盤點類別
			dataIndex: "opcode",
			align: "center",
			width: "10%",
			render: (opcode) => {
				// 在 tabsData 中尋找匹配的 key
				const tab = tabsData.find(tab => tab.key === opcode);
				return tab ? tab.label : opcode; // 如果找到就返回 label，否則返回原 opcode
			},
		},
		{
			title: "预计盘点日", // 預計盤點日
			dataIndex: "plandate",
			align: "center",
			width: "10%",
			render: (text) => dayjs(text).format("YYYY-MM-DD"),
		},
		{
			title: "预计关帐日期", // 預計關帳日期
			dataIndex: "planclosedate",
			align: "center",
			width: "10%",
			render: (text) => dayjs(text).format("YYYY-MM-DD"),
		},
		{
			title: "注记",
			dataIndex: "keynote",
			width: "30%",
			align: "left",
		},
		{
			title: "历程", // 歷程
			dataIndex: "lifeF",
			align: "center",
			width: "10%",
			render: (text) => {
				const map = {
					"0": "創建",
					"1": "复核",
					"2": "初盘",
					"3": "初盘复核",
					"4": "复盘",
					"5": "复盘复核",
					"R": "盘点异动中",
					"E": "盘点异动错误",
					"T": "盘点调整结束",
				}
				return map[text]
			},
		},
		// {
		//   title: t("util.util.updtT"),
		//   dataIndex: "lifeFT",
		//   width: "10%",
		//   align: "center",
		//   render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
		// },
		// {
		//   title: t("util.util.updtID"),
		//   dataIndex: "updtID",
		//   width: "10%",
		//   align: "center",
		//   render: (text) => s_staffs[text] || text,
		// },
		{
			title: "操作",
			dataIndex: "render",
			align: "center",
			width: "7%",
			render: (_, rowData) => (
				<Space size="middle">
					{/* <IconUser /> */}
					{/* <IconDownload onClick={(e) => exportInv(e, rowData)} /> */}
					<IconPen onClick={(e) => editData(e, rowData)} />
					<IconCircle
						disabled={rowData.lifeF === "2" || rowData.lifeF === "E" ? false : true}
						onClick={() => call_txnInvHd.request(rowData)}
					/>
					<IconGarbage
						// disabled={rowData.lifeF === "0" ? false : true}
						onClick={(e) => deleteData(e, rowData)}
					/>
				</Space>
			),
		},
	];

	const tabsData = [
		{
			key: "4020",
			label: "周期盘点", // 週期盤點
		},
		{
			key: "4030",
			label: "专案盘点", // 專案盤點
		},
		{
			key: "4010",
			disabled: true,
			label: "开帐盘点", // 開帳盤點
		},
	];

	console.log("s_tabKey = ", s_tabKey);

	return (
		<div>
			<PageHeader
				title="库房盘点作业"
				extra={[
					<Input
						placeholder="請輸入單號"
						value={s_searchData.N}
						onChange={(e) => handlChange("N", e.target.value)}
					/>,

					<Button
						onClick={() => call_getInvProject.request({ ...s_searchData, opcode: s_tabKey })}
						type="primary"
						icon={<SearchOutlined />}
					/>,
					<Button key="onSearch" type="primary" onClick={() => set_is_showModal(true)}>
						新建
					</Button>,
					// <IconExcelDownload key="IconExcelDownload" className="text-4xl" />,
				]}
			/>

			<CustomTabs
				items={tabsData}
				activeKey={s_tabKey}
				onChange={(activeKey) => {
					set_s_tabKey(activeKey);
				}}
			/>

			<CustomTable
				columns={tableColumns}
				dataSource={s_tableData}
				loading={is_spinning}
				c_lightData={c_lightData}
				tbMode={Object.keys(c_lightData).length > 0}
				onRow={(record) => ({
					onClick: () =>
						record.opUUID === c_lightData.opUUID ? set_c_lightData({}) : set_c_lightData(record),
				})}
			/>

			<CustomModal
				title={
					Object.keys(s_editData).length > 0
						? `${t("util.util.edit")} 周期盘点 專案計畫`
						: `${t("util.util.add")} 专案盘点 專案計畫`
				}
				width="60%"
				open={is_showModal}
				onCancel={() => set_is_showModal(false)}
				afterClose={() => {
					set_s_editData({});
					call_getInvProject.request({ ...s_searchData, opcode: s_tabKey });
				}}
			>
				{/* <ModalContainer
          s_tabKey={s_tabKey}
          s_editData={s_editData}
          set_is_showModal={set_is_showModal}
        /> */}
				{s_tabKey === '4020' ? <ModalContainer
					s_tabKey={s_tabKey}
					s_editData={s_editData}
					set_is_showModal={set_is_showModal}
				/> : <ModalContainer4030
					s_tabKey={s_tabKey}
					s_editData={s_editData}
					set_is_showModal={set_is_showModal}
				/>}

			</CustomModal>
		</div>
	);
});

export default Project;
