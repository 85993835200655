import { BarsOutlined, ExportOutlined } from "@ant-design/icons";
import { Layout, Menu, Switch, message } from "antd";
import { Link, useLocation } from "react-router-dom";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import LogoImg from "../../assets/img/logo_company.png";
// import { IconProduction, IconQuality, IconMaterial } from "../Icon";
import MainItem from "@/auth/auth";
import SATLogo from "../../assets/img/logo-white.png";
import SystemContext from "@/components/Context/SystemContext";
import { logout } from "@/service/apis/ADM/publicAPI";
import styled from "styled-components";
// API
import useAPI from "../../hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Sider } = Layout;

const MyNavbar = (props) => {
  let location = useLocation();
  const { t } = useTranslation();

  const { c_systemConfig, c_userData } = useContext(SystemContext);
  //當前收起狀態 true 為展開
  const [collapsedState, setCollapsedState] = useState(true);
  //collapsedWidth的寬度
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  //是否為shrink狀態
  const [isShrink, setIsShrink] = useState(false);

  const call_logout = useAPI(logout);

  const subItems = [
    {
      label: (
        <div className="flex justify-between items-center">
          <Switch checked={isShrink} onChange={(e) => setIsShrink(e)} />
        </div>
      ),
      key: "changeNavbar",
      icon: <BarsOutlined />,
      title: "",
    },
    {
      label: (
        <div className="flex justify-between items-center">
          <span className="font-semibold">{t("util.sign_out")}</span>
          <span>V 1.1.74 ZK</span>
        </div>
      ),
      key: "logout",
      icon: <ExportOutlined />,
      title: "",
      onClick: () => call_logout.request(),
    },
  ];

  const items = MainItem({ auth: c_systemConfig.auth, c_userData: c_userData } || {});

  useEffect(() => {
    //控制navbar伸縮
    if (props.navbarState === "shrinkClose" && !isShrink) {
      props.setNavbarState("close");
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
    if (props.navbarState === "shrinkClose") {
      return;
    } else if (props.navbarState === "open") {
      setCollapsedState(false);
      setCollapsedWidth(250);
    } else if (props.navbarState === "close" && isShrink) {
      props.setNavbarState("shrinkClose");
      setCollapsedState(true);
      setCollapsedWidth(80);
    } else {
      setCollapsedState(true);
      setCollapsedWidth(0);
    }
  }, [props.navbarState, isShrink]);

  useEffect(() => {
    if (call_logout.status === "suc") {
      message.success("登出成功");
    } else if (call_logout.status === "err") {
      message.success("登出成功");
    }
  }, [call_logout.status]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <Sider
      className={`${props.className} shadow overflow-auto z-10 h-[100vh]`}
      trigger={null}
      collapsible
      theme="light"
      collapsed={collapsedState}
      width={280}
      collapsedWidth={collapsedWidth} //只在collapsed = true 吃設定
      onClick={stop_propagation}
    >
      {/* <div className="pl-4 w-full flex">
        <img src={LogoImg} className="w-full max-w-[150px]" alt="logo" />
      </div> */}

      {/* <div className="pl-8 pt-5 pb-3 w-full flex">
        <img src={SATLogo} className="w-full max-w-[150px]" alt="logo" />
      </div> */}

      <div className="flex flex-col min-h-[calc(100vh_-_80px)]">
        <Menu
          mode="inline"
          size="small"
          className="flex flex-1 flex-col"
          selectedKeys={[location.pathname]}
          items={items}
          onClick={() => props.setNavbarState("close")}
        />
        <Menu mode="inline" items={subItems} />
      </div>
    </Sider>
  );
};

export default MyNavbar;
